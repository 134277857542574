import React, { useState } from "react";
import { PageHeader, Card, Form, Input, Button, Col, Row, Tooltip, notification } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const rules = [{ required: true, message: 'Пожалуйста, введите имя для роли или удалите строчку' }];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 0 },
    md: { span: 8, offset: 0 },
  },
};

const MyForm = ({ roles, token }) => {
  const onFinish = values => {
    fetch('/api/roles', {
      method: "PUT",
      headers: { "Content-Type": "application/json", 'authorization': `Token ${token}` },
      body: JSON.stringify(values)
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(({ _roles }) => notification['success']({ message: 'Роли успешно обновлены' }))
    .catch(() => notification['error']({ message: 'Ошибка на странице ролей' }));
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Роли"
        subTitle="Настройки доступа для пользователей"
      />

      <Card>
      <Form onFinish={onFinish} {...formItemLayoutWithOutLabel} initialValues={{ roles: roles }}>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>

        <Form.List name="roles" {...formItemLayout}>
          {(fields, { add, remove }) => {
            /**
             * `fields` internal fill with `name`, `key`, `fieldKey` props.
             * You can extends this into sub field to support multiple dynamic fields.
             */
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item key={field.key} style={{ marginBottom: '0px' }}>
                    <Input.Group compact>
                      <Form.Item
                        key='1'
                        name={[field.name, "name"]}
                        fieldKey={[field.fieldKey, "name"]}
                        rules={rules}
                      >
                        <Input placeholder="Название роли" />
                      </Form.Item>
                      {index != 0 && <Tooltip title='Удалить' placement='right'>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Tooltip>}
                    </Input.Group>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Добавить роли
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

      </Form>
      </Card>
    </>
  );
};


class RolesPage extends React.Component {
  state = { roles: [] }

  componentDidMount() {
    fetch('/api/roles', {
      headers: { "Content-Type": "application/json", 'authorization': `Token ${this.props.token}` }
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(({ roles }) => this.setState({ roles }))
    .catch(() => notification['error']({ message: 'Ошибка на странице ролей' }));
    // .then(this.setState)
  }

  render() {
    const { roles } = this.state

    if (roles.length > 0) {
      return <MyForm roles={roles} token={this.props.token} />
    } else {
      return <>Загрузка...</>
    }
  }
}

export default RolesPage;

















// <Form.Item key={field.key} style={{ marginBottom: '0px' }}>
//                     <Input.Group compact>
//                       <Form.Item
//                         key='1'
//                         name={[field.name, "name"]}
//                         fieldKey={[field.fieldKey, "name"]}
//                         rules={rules}
//                       >
//                         <Input placeholder="Название" />
//                       </Form.Item>
//                       <Form.Item
//                         key='2'
//                         name={[field.name, "description"]}
//                         fieldKey={[field.fieldKey, "description"]}
//                       >
//                         <Input placeholder="Описание" />
//                       </Form.Item>
//                       {/*{index != 0 && <Tooltip title='Удалить' placement='right'>*/}
//                       {<Tooltip title='Удалить' placement='right'>
//                         <MinusCircleOutlined
//                           className="dynamic-delete-button"
//                           onClick={() => {
//                             remove(field.name);
//                           }}
//                         />
//                       </Tooltip>}
//                     </Input.Group>
//                   </Form.Item>
