import React, { useState } from "react";
import { Checkbox, notification } from 'antd';
import { map, find } from 'lodash';
import reqwest from 'reqwest';

const CommaRolesDisplay = ({ selectedRoles, roles }) => {
  if (!selectedRoles) {
    return <></>;
  }

  const data = map(selectedRoles, (id) => {
    return <span key={id}>{find(roles, a => a.id == id)?.name}</span>
  }).reduce((prev, curr) => [prev, ', ', curr])
  return (
    <>
      {data}
    </>
  );
};

export default CommaRolesDisplay;
