import React, { useState } from "react";
import { Checkbox, notification } from 'antd';
import { map } from 'lodash';

const UserRolesDisplay = ({ user, roles, token, maxWidth }) => {
  if (maxWidth === undefined ) {
    maxWidth = '140px';
  }
  const onChange = (checkedValues) => {
    // replaced by fetch because reqwest doesn't send any data in the role_ids when it is empty array.
    fetch(`/api/users/${user.id}`, {
      method: 'put',
      headers: { "Content-Type": "application/json", 'authorization': `Token ${token}` },
      body: JSON.stringify({ user: { role_ids: checkedValues } })
    }).then(response => {
      if (response.ok) {
        return response;
      }
      throw new Error("Network response was not ok.");
    })
    .then(_data => notification['success']({ message: 'Роли пользователя успешно обновлены' }))
    .catch(() => notification['error']({ message: 'Не удалось обновить роли пользователя' }));
  }

  const options = map(roles, (role, index) => { return { label: role.name, value: role.id } });

  return (
    <Checkbox.Group style={{ maxWidth }} options={options} defaultValue={user.role_ids} onChange={onChange} />
  );
};

export default UserRolesDisplay;

