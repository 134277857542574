import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const disabledDate = current => {
  return current && current < moment().subtract(1, 'days').endOf('day');
};

const disabledDateTime = (current) => {
  return {
    disabledHours: () => current && current.isSameOrBefore(moment().endOf('day')) ? range(0, 24).splice(0, moment().hour()) : false,
    disabledMinutes: () => current && current.isSameOrBefore(moment().endOf('hour')) ? range(0, 24).splice(0, moment().minute()) : false,
  }
}

export { disabledDate, disabledDateTime, locale as datePickerLocale }
