import React from "react";
import { PageHeader, Card, List, Table, notification, Button, Form, Popover, Popconfirm, Input } from 'antd';
import reqwest from 'reqwest';
import { map } from 'lodash';
import locale from 'antd/lib/locale-provider/ru_RU';
import Icon, { SendOutlined } from '@ant-design/icons';
import moment from 'moment';

const SendUserMessage = ({ fetch, message, token }) => {
  const [popoverOpened, setPopoverOpened] = React.useState(false);

  const onFinish = values => {
    reqwest({
      url: `/api/messages/${message.id}/reply`,
      method: 'post',
      headers: { 'authorization': `Token ${token}` },
      data: values,
      type: 'json',
    })
    .then(_data => {
      notification['info']({ message: 'Сообщение успешно отправлено' })
      setPopoverOpened(false)
    })
    .catch(() => notification['error']({ message: 'Не удалось отправить сообщение' }));
  };

  const removeMessage = () => {
    reqwest({
      url: `/api/messages/${message.id}`,
      method: 'delete',
      headers: { 'authorization': `Token ${token}` },
      type: 'json',
    })
    .then(_data => {
      fetch();
      notification['info']({ message: 'Сообщение успешно удалено' })
    })
    .catch(() => notification['error']({ message: 'Не удалось удалить сообщение' }));
  }

  const content = (
    <Form layout="horizontal" onFinish={onFinish}>
      <Form.Item name="message" style={{marginBottom: '5px'}} rules={[{ required: true, message: 'Пожалуйста, введите текст сообщения'}]} >
        <Input.TextArea rows={4} placeholder='Текст сообщения' />
      </Form.Item>
      <Form.Item >
        <Button block type="dashed" htmlType="submit"><SendOutlined /> Отправить</Button>
      </Form.Item>
    </Form>
  );
  return (
    <>
      <Popover placement='left' visible={popoverOpened} onVisibleChange={setPopoverOpened} trigger='click' content={content} title='Ответить на персональное сообщение'>
        <Button type='primary'>Ответить</Button>
      </Popover>
      <Popconfirm
        title="Точно удалить это сообщение?"
        onConfirm={removeMessage}
        onCancel={false}
        okText="Да"
        cancelText="Нет"
      >
        <Button type='danger'>Удалить</Button>
      </Popconfirm>
    </>
  );
};

class Messages extends React.Component {
  state = {
    data: [],
    pagination: { showSizeChanger: true, defaultPageSize: 12, pageSizeOptions: [12, 24, 50, 100] },
    loading: false,
  };

  componentDidMount() {
    this.fetch();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: '/api/messages',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      data: {
        per_page: this.state.pagination.pageSize,
        ...params,
      },
      type: 'json',
    }).then(data => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      pagination.total = data.total_count;

      this.setState({
        loading: false,
        data: data.messages,
        pagination,
      });
    }).catch(() => notification['error']({ message: 'Ошибка...' }));
  };


  render() {
    const { roles, data, pagination, loading } = this.state;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '10%',
      },
      {
        title: 'Пользователь',
        dataIndex: 'username',
        sorter: true,
        render: (username, record) => `${username} (ID: ${record.user_id})`,
        width: '30%',
      },
      {
        title: 'Сообщение',
        dataIndex: 'text',
      },
      {
        title: 'Дата',
        dataIndex: 'created_at',
        render: (text, _item) => moment(text).format('YYYY-MM-DD H:mm')
      },
      {
        title: 'Действия',
        dataIndex: 'actions',
        render: (_nothing, record) => <SendUserMessage fetch={this.fetch} message={record} token={this.props.token} />
      }
    ];
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="Сообщения"
          subTitle="Список сообщений от пользователей Telegram"
        />
        <Table
          locale={locale.Table}
          columns={columns}
          rowKey={record => record.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default Messages
