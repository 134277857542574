import React from "react";
import { Checkbox, PageHeader, Avatar, Card, Tag, List, Form, Tooltip, notification, Input, Button, Select, Modal, Popconfirm, DatePicker } from 'antd';
import reqwest from 'reqwest';
import { omit, map, find as find_ } from 'lodash';
import * as _ from 'lodash';
import locale from 'antd/lib/locale-provider/ru_RU';
import CommaRolesDisplay from './CommaRolesDisplay';
import { ExclamationCircleOutlined, DeleteOutlined, SendOutlined, UnorderedListOutlined, EditOutlined } from '@ant-design/icons';
import { ReactComponent as TelegramSvg } from '../icons/telegram.svg';
import { isMobile } from 'react-device-detect';
import { disabledDate, disabledDateTime, datePickerLocale } from 'utils/date-picker-options';
import moment from 'moment';

const { confirm } = Modal;

class MessagesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      removingAll: false,
      messages: props.messages,
      id: props.id
    };
  }

  removeMessage = (messageId) => {
    return reqwest({
          url: `/api/posts/${this.state.id}/delete_messages`,
          method: 'post',
          data: {
            message_id: messageId,
          },
          headers: { 'authorization': `Token ${this.props.token}` },
          type: 'json',
        }).then(({ messages, removed}) => {
          this.setState({ messages })
          if (removed)
            notification['success']({ message: 'Сообщение удалено' });
          else
            notification['error']({ message: 'Сообщение не было удалено', description:'прошло больше 48 часов или сообщение уже удалено' });
        })
        .catch(() => notification['error']({ message: 'Ошибка...' }));
  }

  removeAllMessages = () => {
    const fetchPosts = this.props.fetchPosts;

    this.setState({ removingAll: true })

    return reqwest({
      url: `/api/posts/${this.state.id}/delete_messages`,
      method: 'post',
      data: {
        all: true
      },
      headers: { 'authorization': `Token ${this.props.token}` },
      type: 'json',
    }).then(({ messages, removed}) => {
      this.setState({ messages, removingAll: false })
      if (removed)
        notification['success']({ message: 'Сообщение удалено у всех' });
      else
        notification['error']({ message: 'Сообщение не было удалено у всех', description:'прошло больше 48 часов или сообщения уже удалены' });

      fetchPosts();

      this.setState({ visible: false });
    })
    .catch(() => notification['error']({ message: 'Ошибка...' }));
  }

  render() {
    const renderList = () => {
      return (
        <List
          style={{ marginTop: '1.5em', height: 300, overflow: 'auto' }}
          size='small'
          bordered
          pagination={false}
          dataSource={this.state.messages}
          renderItem={item => {
            return <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<Avatar src={item.img} />}
                title={<span>{item.name} <Button style={{ float: 'right' }} size='small' danger type='primary' onClick={() => this.removeMessage(item.id)}><DeleteOutlined /> Удалить</Button></span>}
              />
            </List.Item>
          }}
        />
      );
    }
    // style={{ width: (isMobile ? '100wv' : 500), height: 300 }}
    const modalOptions = {
      title: 'Отправлено людям',
      width: isMobile ? '95%' : 500,
      className: 'msg-list-dialog',
      icon: <ExclamationCircleOutlined />,
      content: renderList(),
    }

    const setVisible = (visible) => {
      this.setState({ visible });
    }

    return (
      <>
        <Modal
          {...modalOptions}
          visible={this.state.visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={[
            <Button key="submit" type="primary" onClick={() => setVisible(false)}>
              Закрыть
            </Button>,
          ]}
        >
          <h4>Здесь можно удалить сообщения из чата у получателей</h4>
          <Button block size='small' danger type='primary' loading={this.state.removingAll} icon={<DeleteOutlined />} onClick={this.removeAllMessages}>Удалить у всех</Button>
          {renderList()}
        </Modal>
        <UnorderedListOutlined onClick={() => setVisible(true)} />
      </>
    );
  }
}

class ChangeTextAction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      id: props.id
    };
  }

  handleEditSave = (values) => {
    const fetchPosts = this.props.fetchPosts;

    return reqwest({
      url: `/api/posts/${this.state.id}/change_text`,
      method: 'post',
      data: values,
      headers: { 'authorization': `Token ${this.props.token}` },
      type: 'json',
    }).then(({ edited}) => {
      if (edited)
        notification['success']({ message: 'Сообщение изменено у всех' });
      else
        notification['error']({ message: 'Сообщение не было изменено у всех', description:'прошло больше 48 часов или сообщения уже удалены' });

      fetchPosts();

      this.setState({ visible: false });
    })
    .catch(() => notification['error']({ message: 'Ошибка...' }));
  }

  render() {
    const renderForm = () => {
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
      const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      };
      return (
        <Form {...layout} initialValues={{ new_text: this.props.text }} onFinish={this.handleEditSave}>
          <Form.Item name="new_text" label="Текст сообщения">
            <Input.TextArea rows={10} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Сохранить и изменить
            </Button>
          </Form.Item>
        </Form>
      );
    }
    // style={{ width: (isMobile ? '100wv' : 500), height: 300 }}
    const modalOptions = {
      title: 'Изменить у всех, получивших сообщение',
      width: isMobile ? '95%' : 500,
      className: 'msg-list-dialog',
      icon: <ExclamationCircleOutlined />,
    }

    const setVisible = (visible) => {
      this.setState({ visible });
    }

    return (
      <>
        <Modal
          {...modalOptions}
          visible={this.state.visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={[
            <Button key="submit" type="primary" onClick={() => setVisible(false)}>
              Закрыть
            </Button>,
          ]} >
          {renderForm()}
        </Modal>
        <Button style={{ float: 'right' }} onClick={() => setVisible(true)} type='primary' >
          <EditOutlined />
          Изменить текст
        </Button>
      </>
    );
  }
}

class ListPosts extends React.Component {

  constructor(props) {
    super(props);
    props.fetchCallback(this.externalFetch)
    this.state = {
      data: [],
      pagination: { showSizeChanger: true, onChange: this.onPageChange, onShowSizeChange: this.onPageChange, defaultPageSize: 12, pageSizeOptions: [12, 24, 50, 100] },
      loading: false,
    };
  }

  externalFetch = () => {
    this.fetch()
  }

  componentDidMount() {
    this.fetch();
  }

  onPageChange = (current, pageSize) => {
    const pager = { ...this.state.pagination };
    pager.current = current;

    this.setState({ pagination: pager });
    this.fetch({
      per_page: pageSize,
      page: current
    });
  }

  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: '/api/posts',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      data: {
        per_page: this.state.pagination.pageSize,
        ...params,
      },
      type: 'json',
    }).then(data => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      pagination.total = data.total_count;
      this.setState({
        loading: false,
        data: data.posts,
        pagination,
      });
    }).catch(() => notification['error']({ message: 'Ошибка...' }));
  };

  showConfirm(item) {
    confirm({
      title: 'Вы уверены?',
      icon: <ExclamationCircleOutlined />,
      content: <>Это действие отправит это событие всем людям в этих ролях: <CommaRolesDisplay selectedRoles={item.roles} roles={this.props.roles}/></>,
      onOk: () => {
        return reqwest({
          url: `/api/posts/${item.id}/notify`,
          method: 'post',
          headers: { 'authorization': `Token ${this.props.token}` },
          type: 'json',
        }).then(() => {
          notification['success']({ message: 'Отправлено' });
          setTimeout(this.fetch, 5000);
        })
        .catch(() => notification['error']({ message: 'Ошибка...' }));
      },
      okType: 'danger',
      okText: 'Да',
      cancelText: 'Нет',
      onCancel() {},
    });
  }

  handleRemovePost = (id) => {
    return reqwest({
      url: `/api/posts/${id}`,
      method: 'delete',
      headers: { 'authorization': `Token ${this.props.token}` },
      type: 'json',
    }).then(() => {
      notification['success']({ message: 'Запись удалена' });
      this.fetch();
    })
    .catch(() => notification['error']({ message: 'Ошибка...' }));
  }

  render() {
    const { data, pagination, loading } = this.state;
    const { tg_files } = this.props;

    return (
      <List
        style={{ marginTop: '2em' }}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        pagination={pagination}
        loading={loading}
        dataSource={data}
        renderItem={item => {
          let telegramUploads = (tg_files && tg_files.length > 0 && map(item.telegram_upload_ids, item_id => ( find_(tg_files, (a) => (a.id === item_id)) )) ) || []
          telegramUploads = omit(telegramUploads, _.isUndefined)

          return <List.Item key={item.id}>
            <Card key={item.id} extra={moment(item.created_at).format('YYYY-MM-DD H:mm')} title={item.name}>
              <>
                <div key='1'>Роли: <CommaRolesDisplay selectedRoles={item.roles} roles={this.props.roles}/></div>
                <div key='sent'>
                  {item.sent_messages_count == 0 && <span color='red'>Никому не отправлено</span>}
                  {item.sent_messages_count > 0 && <span>Отправлено: {item.sent_messages_count} сообщений <MessagesList fetchPosts={this.externalFetch} token={this.props.token} messages={item.sent_messages} id={item.id}/></span>}
                </div>
                <div key='sent_at'>
                  {item.use_schedule && !item.sent_at && <span color='red'>Отложенная отправка запланирована на: {moment(item.scheduled_at).format('YYYY-MM-DD H:mm')}</span>}
                  {item.use_schedule && item.sent_at && <span color='green'>Отправлено в {moment(item.sent_at).format('YYYY-MM-DD H:mm')}</span>}
                </div>
                {telegramUploads.length > 0 && <>Файлы Telegram: {telegramUploads.map((upload, index) => {
                  return <div key={index}>{index + 1}: {String(upload.name) || 'Нет заголовка файла'}</div>
                })}</>}
                <br/>
                Текст:
                <div key='4' className="display-linebreak">{item.text}</div>
                <div key='5' style={{marginTop: '8px'}}>
                  <Button style={{ float: 'right' }}type='dashed' onClick={() => this.showConfirm(item)}><SendOutlined /> Отправить еще раз</Button>
                  <Popconfirm  placement="top"
                               title={<>Уверены? <br/>Эта запись пропадет из списков последних событий у людей, но сообщение<br/>не будет удалено, если это не сделать выше вручную</>}
                               okText='Да' cancelText='Нет'
                               onConfirm={() => this.handleRemovePost(item.id)}>
                    <Tooltip title='Удалить' placement='left'>
                      <Button style={{ float: 'right' }} type='danger'>
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                  <ChangeTextAction fetchPosts={this.externalFetch} token={this.props.token} text={item.text} id={item.id}/>
                </div>
              </>
            </Card>
          </List.Item>
        }}
      />
    );
  }
}

class Posts extends React.Component {
  formRef = React.createRef();

  formInitialValues = { send_now: false };

  state = {
    roles: [],
    tg_files: []
  };

  componentDidMount() {
    reqwest({
      url: '/api/telegram_uploads',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      type: 'json',
    }).then(({ files }) => this.setState({ tg_files: files }))
    .catch(() => notification['error']({ message: 'Ошибка получения файлов Telegram...' }));

    reqwest({
      url: '/api/roles',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      type: 'json',
    }).then(({ roles }) => this.setState({ roles }))
    .catch(() => notification['error']({ message: 'Ошибка...' }));
  }

  onFinish = values => {
    reqwest({
      url: '/api/posts',
      method: 'post',
      headers: { 'authorization': `Token ${this.props.token}` },
      data: values,
      type: 'json',
    }).then(() => {
      notification['success']({ message: (values.send_now ? 'Запись сохранена и отправлена' : 'Запись сохранена'), description: !values.send_now && 'Можно отправить ее из списка внизу' });
      this.resetForm();
      this.fetchCallback();
    })
    .catch(() => notification['error']({ message: 'Ошибка...' }));
  };

  handleSend = () => {
    this.formRef.current.setFieldsValue({
      send_now: true
    });
    this.formRef.current.submit();
  }

  resetForm = () => {
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue(this.formInitialValues);
  }

  render() {
    const { roles, tg_files } = this.state;
    const roleOptions = map(roles, (role, index) => { return { label: role.name, value: role.id } });
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    const tgTagRender = ({ label, value, closable, onClose }) => {
      const file = find_(tg_files, (a) => (a.id === value))

      return (
        <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
          {file && file.name || `Без имени, добавлен: ${moment(file.created_at).format('YYYY-MM-DD H:mm')}`}
        </Tag>
      );
    }

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="Записи"
          subTitle="Сообщения, которые смогут увидеть и последующие пользователи"
        />
        <Card>
          <Form {...layout} ref={this.formRef} initialValues={this.formInitialValues} onFinish={this.onFinish}>
            <Form.Item name="role_ids" label="Кому отправлять" rules={[{ required: true, message: 'Необходимо выбрать, кому отправлять сообщение' }]}>
              <Checkbox.Group options={roleOptions} />
            </Form.Item>
            <Form.Item name="name" label="Название события" rules={[{ required: true, message: 'Название события удобно было бы видеть' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="text" label="Текст сообщения" rules={[{ required: true, message: 'Необходимо написать сообщение' }]}>
              <Input.TextArea rows={10} placeholder='Текст сообщения можно не вводить только если есть файл' />
            </Form.Item>
            <Form.Item name="use_schedule" valuePropName="checked" label="Отложенная публикация" >
              <Checkbox />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.use_schedule !== currentValues.use_schedule} >
              {({ getFieldValue }) =>
                getFieldValue('use_schedule') === true ? (
                  <Form.Item
                    name="scheduled_at"
                    label="Время публикации"
                    rules={[
                      {
                        required: (getFieldValue('use_schedule') === true),
                        message: 'Выберете время публикации',
                      },
                    ]}
                  >
                    <DatePicker locale={datePickerLocale} disabledDate={disabledDate} disabledTime={disabledDateTime} showTime format="YYYY-MM-DD HH:mm" placeholder="Дата и время" />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            { tg_files.length > 0 &&
              <Form.Item name="telegram_upload_ids" label="Добавить файлы для Telegram">
                <Select placeholder="Выберите файл" allowClear mode="multiple" tagRender={tgTagRender}>
                  { map(tg_files, (data) => <Select.Option key={data.id} value={data.id}>{`${data.name} (${data.tg_type_text}, добавлен: ${moment(data.created_at).format('YYYY-MM-DD H:mm')})`}</Select.Option>) }
                </Select>
              </Form.Item>
            }
            <Form.Item name="send_now" valuePropName="checked" style={{ display: 'none' }}>
              <Checkbox />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.use_schedule !== currentValues.use_schedule} >
                {({ getFieldValue }) =>
                  <Button type="primary" htmlType="submit">
                    {getFieldValue('use_schedule') === true ? 'Сохранить и отправить в назначенное время' : 'Сохранить и не отправлять'}
                  </Button>
                }
              </Form.Item>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.use_schedule !== currentValues.use_schedule} >
                {({ getFieldValue }) =>
                  getFieldValue('use_schedule') !== true ? (
                    <Button type="danger" onClick={this.handleSend}>
                      Сохранить и отправить
                    </Button>
                  ) : null
                }
              </Form.Item>
            </Form.Item>
          </Form>
        </Card>

        <ListPosts fetchCallback={(a) => this.fetchCallback = a } key="2" roles={roles} tg_files={tg_files} token={this.props.token}/>
      </>
    );
  }
}

export default Posts
