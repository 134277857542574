// export const setAdminName = (store, adminName) => {
//   window.localStorage.setItem('adminName', adminName)
//   store.setState({ adminName });
// };

// export const setToken = (store, token) => {
//   window.localStorage.setItem('token', token)
//   store.setState({ token });
// };

export const signIn = (store, token, adminName) => {
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('adminName', adminName)
  store.setState({ token, adminName });
};

export const signOut = (store) => {
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('adminName')
  store.setState({ token: '', adminName: '' });
};
