import React from "react";
import { ConfigProvider } from 'antd';
import ruLang from 'antd/lib/locale/ru_RU';
import useGlobal from "../store";
import Routes from "../routes/Index";
import Login from "./Login";
import zhCN from 'antd/lib/locale/zh_CN';

const App = () => {
  const [globalState, globalActions] = useGlobal();

  if (globalState.token && globalState.token.length > 0) {
    return <Routes />
  } else {
    return <Login />
  }
};

export default () => (
  <ConfigProvider language={ruLang}>
    <App />
  </ConfigProvider>
);
