import React from "react";
import useGlobalHook from "utils/use-global-hook";

import * as actions from './actions';

const initialState = {
  token: window.localStorage.getItem('token') || '',
  adminName: window.localStorage.getItem('adminName') || '',
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
