import React from "react";
import { Layout, Menu, Form, Input, Button, Checkbox, PageHeader, notification } from 'antd';
import useGlobal from "../store";
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;

const layout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 }
  }
};
const tailLayout = {
  wrapperCol: {
    xs: { offset: 0 },
    md: { offset: 8 }
  }
};

export default () => {
  const [globalState, globalActions] = useGlobal();
  const onFinish = values => {
    const url = "/api/auth";
      fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values)
      }).then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then(({ adminName, token }) => {
          // globalActions.setAdminName(adminName)
          // globalActions.setToken(token)
          globalActions.signIn(token, adminName)
          notification['success']({ message: 'Вы успешно вошли в систему' });
        })
        .catch(() => {
          notification['error']({ message: 'Ошибка входа', description: 'Возможно вы ввели неверный пароль' });
        });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (<Layout>
    <Content style={{ margin: '24px 16px 0', height: '100vh' }}>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item {...tailLayout}>
          <PageHeader title='Вход в систему' style={{ paddingLeft: 0 }}/>
        </Form.Item>

        <Form.Item
          label="Имя пользователя"
          name="username"
          rules={[{ required: true, message: 'Пожалуйста, введите имя пользователя'}]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Content>
  </Layout>);
};
