import React from "react";
import { PageHeader, Card, Table, notification, Popover, Form, Input, Button, Tooltip, List } from 'antd';
import Icon, { UserOutlined, SaveTwoTone, SendOutlined, SearchOutlined, DeleteOutlined, RollbackOutlined } from '@ant-design/icons';
import reqwest from 'reqwest';
import { map } from 'lodash';
import UserRolesDisplay from './UserRolesDisplay';
import { ReactComponent as TelegramSvg } from '../icons/telegram.svg';
import { ReactComponent as ViberSvg } from '../icons/viber.svg';
import { isMobile } from 'react-device-detect';

// const isMobile = true
const { Search } = Input;

const SendUserMessage = ({ user, token }) => {
  const [popoverOpened, setPopoverOpened] = React.useState(false);

  const onFinish = values => {
    reqwest({
      url: `/api/users/${user.id}/send_message`,
      method: 'post',
      headers: { 'authorization': `Token ${token}` },
      data: values,
      type: 'json',
    })
    .then(_data => {
      notification['info']({ message: 'Сообщение успешно отправлено' })
      setPopoverOpened(false)
    })
    .catch(({ status, response }) => {
      try {
        const responseJSON = JSON.parse(response)
        notification['error']({ message: `Не удалось отправить сообщение: ${responseJSON.error}` })
      } catch (e) {
        notification['error']({ message: `Не удалось отправить сообщение: ${response}` })
      }
    });
  };

  const content = (
    <Form layout="horizontal" onFinish={onFinish}>
      <Form.Item name="message" style={{marginBottom: '5px'}} rules={[{ required: true, message: 'Пожалуйста, введите текст сообщения'}]} >
        <Input.TextArea rows={4} placeholder='Текст сообщения' />
      </Form.Item>
      <Form.Item shouldUpdate={true}>
        <Button block type="dashed" htmlType="submit"><SendOutlined /> Отправить</Button>
      </Form.Item>
    </Form>
  );
  return (
    <Popover placement='left' visible={popoverOpened} onVisibleChange={setPopoverOpened} trigger='click' content={content} title='Отправить персональное сообщение'>
      <Tooltip placement='left' title='Персональное сообщение'><Button type='primary'><SendOutlined /></Button></Tooltip>
    </Popover>
  );
};
const UserNameChange = ({ user, token }) => {
  const onFinish = values => {
    if (values.internal_name === undefined)
      return

    reqwest({
      url: `/api/users/${user.id}`,
      method: 'put',
      headers: { 'authorization': `Token ${token}` },
      data: { user: values },
      type: 'json',
    })
    .then(_data => notification['success']({ message: 'Имя пользователя обновлено' }))
    .catch(() => notification['error']({ message: 'Не удалось обновить имя пользователя' }));
  };

  return (
    <Form layout="inline" initialValues={{ internal_name: user.internal_name }} onFinish={onFinish}>
      <Form.Item>
        <Form.Item name="internal_name" style={{ display: 'inline-block', width: 'calc(100% - 90px)', marginRight: '5px' }}>
          <Input placeholder='Добавить имя' prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', width: '50px', marginRight: '0px' }}>
          <Tooltip placement='right' title='Сохранить'><Button type="dashed" htmlType="submit"><SaveTwoTone /></Button></Tooltip>
        </Form.Item>
      </Form.Item>
    </Form>
  );
};

const DisableUserButton = ({ user, token, after, ...rest }) => {
  const handleClick = () => {
    reqwest({
      url: `/api/users/${user.id}`,
      method: 'put',
      headers: { 'authorization': `Token ${token}` },
      data: { user: { disabled: !user.disabled } },
      type: 'json',
    })
    .then(_data => {
      notification['success']({ message: 'Статус обновлен' });
      after();
    })
    .catch(() => notification['error']({ message: 'Не удалось обновить статус' }));
  };

  return (
    <Tooltip placement='left' title={user.disabled ? 'Включить пользователя' : 'Отключить пользователя'}>
      {user.disabled ? 'Выключен' : ''}
      <Button {...rest} onClick={handleClick} type={user.disabled ? 'primary' : 'danger'}>
        {user.disabled ? <RollbackOutlined /> : <DeleteOutlined />}
      </Button>
    </Tooltip>
  );
};

const IdAdminIcon = ({ user }) => {
  if (user.admin) {
    return <>{user.id} <Tooltip placement='right' title='Администратор (в вайбер и Telegram)'>👑</Tooltip></>
  } else {
    return user.id;
  }
};

class Users extends React.Component {
  onPageChange = (current, pageSize) => {
    const pager = { ...this.state.pagination };
    pager.current = current;

    this.setState({
      pagination: pager,
      pageSize: pageSize
    }, this.fetch);
  }

  state = {
    roles: [],
    data: [],
    search: '',
    pageSize: 10,
    pagination: { size: 'small', showSizeChanger: true, onChange: this.onPageChange, onShowSizeChange: this.onPageChange, defaultPageSize: 10 },
    sorter: {},
    filters: { messenger_type: null, id: null, actions: ['non_removed'] },
    loading: false,
  };

  componentDidMount() {
    this.fetch();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({
      pagination: pager,
      sorter: sorter,
      filters: filters
    }, this.fetch);
  };

  onSearch = value => {
    const newState = { search: value };
    if (value.trim().length === 0) {
      newState.pagination = { ...this.state.pagination };
      newState.pagination.pageSize = 10;
      newState.pageSize = 10;
    }
    console.log(newState)
    this.setState(newState, this.fetch);
  };

  fetch = () => {
    this.setState({ loading: true });
    const requestData = {
      search: this.state.search,
      per_page: this.state.pageSize,
      sortField: this.state.sorter.field,
      page: this.state.pagination.current,
      sortOrder: this.state.sorter.order,
      filters: this.state.filters,
    };

    reqwest({
      url: '/api/users',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      data: requestData,
      type: 'json',
    }).then(data => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      pagination.total = data.total_count;
      pagination.current = data.page;
      pagination.pageSize = data.per_page;

      this.setState({
        loading: false,
        pageSize: data.per_page,
        roles: data.roles,
        data: data.users,
        pagination,
      });
    }).catch(() => notification['error']({ message: 'Ошибка...' }));
  };


  render() {
    const { roles, data, pagination, loading } = this.state;
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        filters: [
          { text: <>👑 Админ</>, value: 'admin' },
          { text: <>Не админ</>, value: 'non_admin' },
        ],
        render: (id, record) => <IdAdminIcon user={record} />,
        width: '20px',
      },{
        title:<Tooltip placement='right' title='Мессенджер'>М</Tooltip>,
        dataIndex: 'messenger_type',
        sorter: true,
        filters: [
          { text: <><TelegramSvg /> Telegram</>, value: 'telegram' },
          { text: <><ViberSvg /> Viber</>, value: 'viber' },
        ],
        render: msg_type => {
          if (msg_type === 'viber') {
            return <Tooltip placement='right' title='Viber'><ViberSvg style={{fontSize: '2em'}} /></Tooltip>
          } else {
            return <Tooltip placement='right' title='Telegram'><TelegramSvg style={{fontSize: '2em'}} /></Tooltip>
          }
        },
        width: '32px',
      },
      {
        title: 'Логин',
        dataIndex: 'username',
        sorter: true,
        width: '10%',
      },
      {
        title: 'Полное имя',
        dataIndex: 'first_name',
        render: (_name, record) => renderFullName(record),
        width: '15%',
      },
      {
        title: 'Вручную заданное имя',
        width: '25%',
        dataIndex: 'internal_name',
        render: (_internal_name, record) => <UserNameChange token={this.props.token} user={record}/>,
      },
      {
        title: 'Роли',
        dataIndex: 'role_ids',
        width: '180px',
        render: (_role_ids, record) => <UserRolesDisplay token={this.props.token} roles={roles} user={record}/>,
      },
      {
        title: 'Действия',
        width: '40px',
        dataIndex: 'actions',
        defaultFilteredValue: ['non_removed'],
        filters: [
          { text: 'Только выключенные', value: 'removed' },
          { text: 'Только НЕ выключенные', value: 'non_removed' },
        ],
        render: (_nothing, record) => (<>
          <SendUserMessage user={record} token={this.props.token} />
          <br/>
          <DisableUserButton style={{marginTop: '5px'}} user={record} token={this.props.token} after={this.fetch} />
        </>)
      }
    ];

    const renderFullName = (record) => {
      if (record.messenger_type == 'viber')
        return <Popover placement='right' content={<img src={record.viber_avatar_path}/>} title={false}>
          <img width='64px' height='64px' src={record.viber_avatar_path}/>
        </Popover>
      else {
        const name = record.manual_name ? record.manual_name : `${record.first_name ? record.first_name : ''} ${record.last_name ? record.last_name : ''}`
        if (record.telegram_avatar_path) {
          return <Popover placement='right'  content={<img width='256px' height='256px' src={record.telegram_avatar_path}/>} title={false}>
            <img width='64px' height='64px' style={{marginRight: '8px'}}  src={record.telegram_avatar_path}/>
            { name }
          </Popover>
        } else {
          return name
        }
      }
    }

    let content = null;
    if (isMobile) {
      content = (
        <List
        style={{ marginTop: '2em' }}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        pagination={pagination}
        loading={loading}
        dataSource={data}
        renderItem={item => {
          return <List.Item key={item.id}>
            <Card style={item.disabled ? { backgroundColor: '#ccc'} : {}} key={item.id} extra={<>ID: <IdAdminIcon user={item} /></>} title={item.username}>
              <>
                <div>Мессенджер: {item.messenger_type === 'viber' ? <ViberSvg style={{fontSize: '2em'}} /> : <TelegramSvg style={{fontSize: '2em'}} />}</div><hr/>
                <div>Логин: {item.username}</div><hr/>
                <div>Полное имя: {renderFullName(item)}</div><hr/>
                <div>Вручную заданное имя: <UserNameChange token={this.props.token} user={item}/></div><hr/>
                <div>Роли: <br/> <UserRolesDisplay maxWidth='100%' token={this.props.token} roles={roles} user={item}/></div><hr/>
                <div>
                  Действия:
                  <SendUserMessage user={item} token={this.props.token} />
                  <DisableUserButton style={{marginLeft: '5px'}} user={item} token={this.props.token} after={this.fetch} />
                </div>
              </>
            </Card>
          </List.Item>
        }}
      />
      );
    } else {
      content = (
        <Table
          columns={columns}
          rowClassName={(item) => item.disabled ? 'user-disabled' : 'enabled'}
          rowKey={item => item.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
      );
    }
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="Пользователи"
          subTitle="Список пользователей для получения сообщений"
        />
        <Card style={{ marginBottom: '1em' }}>
          <Search style={{ display: 'inline-block' }} placeholder="Поиск по имени" onSearch={this.onSearch} enterButton />
        </Card>
        {content}
      </>
    );
  }
}

export default Users
