import React from "react";
import { Checkbox, PageHeader, Card, Form, notification, Input, Table, Button, Select, Tag, Modal, DatePicker, Tooltip } from 'antd';
import reqwest from 'reqwest';
import { map, find as find_ } from 'lodash';
import locale from 'antd/lib/locale-provider/ru_RU';
import CommaRolesDisplay from './CommaRolesDisplay';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { disabledDate, disabledDateTime, datePickerLocale } from 'utils/date-picker-options';
import moment from 'moment';

const { confirm } = Modal;

class ListNotifications extends React.Component {
  constructor(props) {
    super(props);
    props.fetchCallback(this.externalFetch)
    this.state = {
      data: [],
      pagination: { showSizeChanger: true, defaultPageSize: 12, pageSizeOptions: [12, 24, 50, 100] },
      loading: false,
    };
  }

  externalFetch = () => {
    this.fetch()
  }

  componentDidMount() {
    this.fetch();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      per_page: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    reqwest({
      url: '/api/notifications',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      data: {
        per_page: this.state.pagination.pageSize,
        ...params,
      },
      type: 'json',
    }).then(data => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      pagination.total = data.total_count;
      this.setState({
        loading: false,
        data: data.notifications,
        pagination,
      });
    }).catch(() => notification['error']({ message: 'Ошибка...' }));
  };

  showConfirm(item) {
    confirm({
      title: 'Вы уверены?',
      icon: <ExclamationCircleOutlined />,
      content: <>Это действие отправит это уведомление всем людям в этих ролях: <CommaRolesDisplay selectedRoles={item.roles} roles={this.props.roles}/></>,
      onOk: () => {
        return reqwest({
          url: `/api/notifications/${item.id}/notify`,
          method: 'post',
          headers: { 'authorization': `Token ${this.props.token}` },
          type: 'json',
        }).then(() => {
          notification['success']({ message: 'Отправлено' });
        })
        .catch(() => notification['error']({ message: 'Ошибка...' }));
      },
      okType: 'danger',
      okText: 'Да',
      cancelText: 'Нет',
      onCancel() {},
    });
  }

  render() {
    const { data, pagination, loading } = this.state;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '4%',
      },
      {
        title: 'Роли пользователей',
        dataIndex: 'roles',
        render: (role_ids, record) => {
          let icons = []
          return <>{icons}<CommaRolesDisplay selectedRoles={role_ids} roles={this.props.roles}/></>
        },
        width: '20%',
      },
      {
        title: 'Текст уведомления',
        dataIndex: 'text',
      },
      {
        title: 'Дата',
        dataIndex: 'created_at',
        width: '10%',
        render: (text, item) => {
          let dateToRender = text;
          let bgColor = 'initial';
          let tooltipText = `Создано и отправлено: ${moment(dateToRender).format('YYYY-MM-DD H:mm')}`;
          if (item.use_schedule && !item.sent_at) {
            dateToRender = item.scheduled_at;
            bgColor = '#ffcb6b';
            tooltipText = `Создано в ${moment(item.created_at).format('YYYY-MM-DD H:mm')}, отправка запланирована на: ${moment(dateToRender).format('YYYY-MM-DD H:mm')}`
          }
          if (item.use_schedule && item.sent_at) {
            dateToRender = item.sent_at;
            bgColor = '#c2ffc2';
            tooltipText = `Создано в ${moment(item.created_at).format('YYYY-MM-DD H:mm')}, отправлено в ${moment(dateToRender).format('YYYY-MM-DD H:mm')}`
          }
          return (
            <Tooltip title={tooltipText} placement='left'>
              <span style={{ backgroundColor: bgColor }}>{moment(dateToRender).format('YYYY-MM-DD H:mm')}</span>
            </Tooltip>
          )
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'actions',
        render: (_, item) => <Button onClick={() => this.showConfirm(item)}>Отправить еще раз</Button>
      },
    ];
    return (
      <Table
        style={{ marginTop: '2em' }}
        locale={locale.Table}
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={this.handleTableChange}
      />
    );
  }
}

class Notifications extends React.Component {
  state = {
    roles: [],
    tg_files: [],
  };

  componentDidMount() {
    reqwest({
      url: '/api/telegram_uploads',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      type: 'json',
    }).then(({ files }) => this.setState({ tg_files: files }))
    .catch(() => notification['error']({ message: 'Ошибка получения файлов Telegram...' }));

    reqwest({
      url: '/api/roles',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      type: 'json',
    }).then(({ roles }) => this.setState({ roles }))
    .catch(() => notification['error']({ message: 'Ошибка...' }));
  }

  onFinish = values => {
    reqwest({
      url: '/api/notifications/send_notification',
      method: 'post',
      headers: { 'authorization': `Token ${this.props.token}` },
      data: values,
      type: 'json',
    }).then(() => {
      notification['success']({ message: 'Уведомление отправлено' });
      window.location.reload()
      // this.fetchCallback();
    })
    .catch(() => notification['error']({ message: 'Ошибка отправки уведомления' }));
  };

  render() {
    const { roles, tg_files } = this.state;
    const roleOptions = map(roles, (role, index) => { return { label: role.name, value: role.id } });
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    const tgTagRender = ({ label, value, closable, onClose }) => {
      const file = find_(tg_files, (a) => (a.id === value))

      return (
        <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
          {file && file.name || `Без имени, добавлен: ${moment(file.created_at).format('YYYY-MM-DD H:mm')}`}
        </Tag>
      );
    }
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="Уведомления"
          subTitle="Отправка быстрых уведомлений пользователям"
        />
        <Card>
          <Form {...layout} onFinish={this.onFinish}>
            <Form.Item name="role_ids" label="Кому отправлять" rules={[{ required: true, message: 'Необходимо выбрать, кому отправлять сообщение' }]}>
              <Checkbox.Group options={roleOptions} />
            </Form.Item>
            <Form.Item name="text" label="Текст сообщения" rules={[{ required: true, message: 'Необходимо написать сообщение' }]}>
              <Input.TextArea placeholder='Текст сообщения можно не вводить только если есть файл'/>
            </Form.Item>
            <Form.Item name="use_schedule" valuePropName="checked" label="Отложенная публикация" >
              <Checkbox />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.use_schedule !== currentValues.use_schedule} >
              {({ getFieldValue }) =>
                getFieldValue('use_schedule') === true ? (
                  <Form.Item
                    name="scheduled_at"
                    label="Время публикации"
                    rules={[
                      {
                        required: (getFieldValue('use_schedule') === true),
                        message: 'Выберете время публикации',
                      },
                    ]}
                  >
                    <DatePicker locale={datePickerLocale} disabledDate={disabledDate} disabledTime={disabledDateTime} showTime format="YYYY-MM-DD HH:mm" placeholder="Дата и время" />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            { tg_files.length > 0 &&
              <Form.Item name="telegram_upload_ids" label="Добавить файлы для Telegram">
                <Select placeholder="Выберите файл" allowClear mode="multiple" tagRender={tgTagRender}>
                  { map(tg_files, (data) => <Select.Option key={data.id} value={data.id}>{`${data.name} (${data.tg_type_text}, добавлен: ${moment(data.created_at).format('YYYY-MM-DD H:mm')})`}</Select.Option>) }
                </Select>
              </Form.Item>
            }
            <Form.Item {...tailLayout}>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.use_schedule !== currentValues.use_schedule} >
                {({ getFieldValue }) =>
                  <Button type="primary" htmlType="submit">
                    {getFieldValue('use_schedule') === true ? 'Отправить в назначенное время' : 'Отправить сейчас'}
                  </Button>
                }
              </Form.Item>
            </Form.Item>
          </Form>
        </Card>

        <ListNotifications fetchCallback={(a) => this.fetchCallback = a } key="2" roles={roles} token={this.props.token}/>
      </>
    );
  }
}

export default Notifications
