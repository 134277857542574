import React, { useState, useEffect } from "react";
import { Layout, Menu } from 'antd';
import useGlobal from "../store";
import { find } from 'lodash';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import {
  LogoutOutlined,
  HomeOutlined,
  ExperimentOutlined,
  UserOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { isMobile } from "react-device-detect";
import reqwest from 'reqwest';

import Posts from "../components/Posts";
import Users from "../components/Users";
import Notifications from "../components/Notifications";
import Messages from "../components/Messages";
import Roles from "../components/Roles";

const { Content, Sider } = Layout;
const allKeys = ['/users', '/roles', '/notifications', '/messages', 'out', '/']

export default () => {
  const [globalState, globalActions] = useGlobal();

  const pathname = window.location.pathname;
  const currentKey = find(allKeys, key => pathname.startsWith(key));
  const [selectedKeys, setSelectedKeys] = useState([currentKey]);
  const [version, setVersion] = useState();
  const [smallScreen, setSmallScreen] = useState(isMobile || window.innerWidth < 1200); // Slider's breakpoint="xl" is 1200 width[]

  const getVersion = () => {
    reqwest({
      url: '/version',
      method: 'get',
      error: function (response) {
        setVersion(response.response);
      },
      success: function (data) {
        setVersion(data);
      }
    });
  }

  useEffect(getVersion, []);

  return (
    <Router>
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          onCollapse={(collapsed, _type) => setSmallScreen(collapsed)}
          style={{
            zIndex: 100,
            //width: 200,// default
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <Menu theme="dark"
                mode="inline"
                onClick={({ _item, key, _keyPath, _domEvent }) => setSelectedKeys([key])}
                selectedKeys={selectedKeys}
          >
            <Menu.Item key="/">
              <Link to='/'>
                <HomeOutlined />
                <span className="nav-text">Записи</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/users">
              <Link to='/users'>
                <UserOutlined />
                <span className="nav-text">Пользователи</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/notifications">
              <Link to='/notifications'>
                <NotificationOutlined />
                <span className="nav-text">Уведомления</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/messages">
              <Link to='/messages'>
                <NotificationOutlined />
                <span className="nav-text">Сообщения</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/roles">
              <Link to='/roles'>
                <ExperimentOutlined />
                <span className="nav-text">Роли</span>
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="out" onClick={() => globalActions.signOut()}>
              <LogoutOutlined />
              <span className="nav-text">Выйти</span>
            </Menu.Item>
            <Menu.Item disabled key="version">
              <span className="nav-text">Версия: {version}</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: smallScreen ? 0 : 200 }}>
          <Content style={{ overflow: 'initial', padding: '0px 24px 24px 24px' }}>
            <Switch>
              <Route exact path="/" >
                <Posts token={globalState.token} />
              </Route>
              <Route eaxt path="/users" exact>
                <Users token={globalState.token} />
              </Route>
              <Route eaxt path="/notifications">
                <Notifications token={globalState.token} />
              </Route>
              <Route eaxt path="/messages">
                <Messages token={globalState.token} />
              </Route>
              <Route eaxt path="/roles" exact>
                <Roles token={globalState.token} />
              </Route>
              <Redirect to="/" />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};
